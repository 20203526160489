import { WebPlayer } from "@tv4/avod-web-player";
import { storage } from "@tv4/avod-web-player-common";

import { toggleQueryParameter } from "./utils";

// @ts-expect-error it's just how it is
// eslint-disable-next-line no-underscore-dangle -- global
const player: WebPlayer = window.__player;

const playButton = document.querySelector<HTMLButtonElement>(
  "#play-button"
) as HTMLButtonElement;
const pauseButton = document.querySelector<HTMLButtonElement>(
  "#pause-button"
) as HTMLButtonElement;

const enterFullscreenButton = document.querySelector<HTMLButtonElement>(
  "#enter-fullscreen-button"
) as HTMLButtonElement;

const showSkinButton = document.querySelector<HTMLButtonElement>(
  "#show-skin-button"
) as HTMLButtonElement;
const hideSkinButton = document.querySelector<HTMLButtonElement>(
  "#hide-skin-button"
) as HTMLButtonElement;
const showInactivePromptButton = document.querySelector<HTMLButtonElement>(
  "#show-inactive-prompt"
) as HTMLButtonElement;

const playerDestroyButton = document.querySelector<HTMLButtonElement>(
  "#player-destroy"
) as HTMLButtonElement;

const autoLoadAsset = document.querySelector<HTMLInputElement>(
  "#auto-load-asset"
) as HTMLInputElement;
autoLoadAsset.checked = storage.getSessionData("autoLoadAsset") === true;

const skipPreroll = document.querySelector<HTMLInputElement>(
  "#skip-preroll"
) as HTMLInputElement;
skipPreroll.checked = storage.getSessionData("skipPreroll") === true;

const mutedPlayback = document.querySelector<HTMLInputElement>(
  "#start-muted"
) as HTMLInputElement;
mutedPlayback.checked = storage.getSessionData("mutedPlayback") === true;

const debugView = document.querySelector<HTMLInputElement>(
  "#enable-debug"
) as HTMLInputElement;

playButton.addEventListener("click", () => {
  player.play();
});
pauseButton.addEventListener("click", () => {
  player.pause();
});
enterFullscreenButton.addEventListener("click", () => {
  player.enterFullscreen();
});
showSkinButton.addEventListener("click", () => {
  player.showSkin();
});
hideSkinButton.addEventListener("click", () => {
  player.hideSkin();
});
showInactivePromptButton.addEventListener("click", () => {
  player.inactiveUsageDetected();
});
playerDestroyButton.addEventListener("click", () => {
  player.destroy();
});

const usePreviewMode = document.querySelector<HTMLInputElement>(
  "#use-preview-mode"
) as HTMLInputElement;
usePreviewMode.checked = storage.getSessionData("usePreviewMode") === true;

const hideMetadataOutsideFullscreen = document.querySelector<HTMLInputElement>(
  "#hide-metata-outside-fullscreen"
) as HTMLInputElement;
hideMetadataOutsideFullscreen.checked =
  storage.getSessionData("hideMetadataOutsideFullscreen") === true;

const showMobileMuteButton = document.querySelector<HTMLInputElement>(
  "#show-mobile-mute-button"
) as HTMLInputElement;
showMobileMuteButton.checked =
  storage.getSessionData("showMobileMuteButton") === true;

const useStartOver = document.querySelector<HTMLInputElement>(
  "#use-startover"
) as HTMLInputElement;
useStartOver.checked = storage.getSessionData("useStartOver") === true;

const debugYospace = document.querySelector<HTMLInputElement>(
  "#debug-yospace"
) as HTMLInputElement;
debugYospace.checked = storage.getSessionData("debugYospace") === true;

const useDevCC = document.querySelector<HTMLInputElement>(
  "#use-dev-cc"
) as HTMLInputElement;
useDevCC.checked = storage.getSessionData("useDevCC") === true;

autoLoadAsset.addEventListener("change", (event) => {
  const enabled = (event.target as HTMLInputElement).checked;
  storage.setSessionData("autoLoadAsset", !!enabled);
  toggleQueryParameter("autoplay", 1);
  location.reload();
});

skipPreroll.addEventListener("change", (event) => {
  const enabled = (event.target as HTMLInputElement).checked;
  storage.setSessionData("skipPreroll", !!enabled);
  toggleQueryParameter("t", 20);
  location.reload();
});

usePreviewMode.addEventListener("change", (event) => {
  const enabled = (event.target as HTMLInputElement).checked;
  storage.setSessionData("usePreviewMode", !!enabled);
  toggleQueryParameter("preview", 1);
  location.reload();
});

hideMetadataOutsideFullscreen.addEventListener("change", (event) => {
  const enabled = (event.target as HTMLInputElement).checked;
  storage.setSessionData("hideMetadataOutsideFullscreen", !!enabled);
  toggleQueryParameter("hideMetadataOutsideFullscreen", 1);
  location.reload();
});

showMobileMuteButton.addEventListener("change", (event) => {
  const enabled = (event.target as HTMLInputElement).checked;
  storage.setSessionData("showMobileMuteButton", !!enabled);
  toggleQueryParameter("showMobileMuteButton", 1);
  location.reload();
});

useStartOver.addEventListener("change", (event) => {
  const enabled = (event.target as HTMLInputElement).checked;
  storage.setSessionData("useStartOver", !!enabled);
  toggleQueryParameter("useStartOver", 1);
  location.reload();
});

mutedPlayback.addEventListener("change", (event) => {
  const enabled = (event.target as HTMLInputElement).checked;
  storage.setSessionData("mutedPlayback", !!enabled);
  toggleQueryParameter("muted", 1);
  location.reload();
});

debugView.addEventListener("change", (_event) => {
  window.dispatchEvent(new CustomEvent("showDebugOverlay"));
});

debugYospace.addEventListener("change", (event) => {
  const enabled = (event.target as HTMLInputElement).checked;
  storage.setSessionData("debugYospace", !!enabled);
  toggleQueryParameter("debugYospace", 1);
  location.reload();
});

useDevCC.addEventListener("change", (event) => {
  const enabled = (event.target as HTMLInputElement).checked;
  storage.setSessionData("useDevCC", !!enabled);
  toggleQueryParameter("useDevCC", 1);
  location.reload();
});
